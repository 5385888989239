<!--
 * @Date: 2021-09-28 14:03:18
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-09-28 14:03:21
 * @Description: 部门列表
 * @FilePath: \sass_web\maintenance_expert\src\views\SystemSettings\department\index.vue
-->
<template>
  <div class='JZContainer'>
    <ContentBlock class="JZTreeContainer h-100 ">
      <el-input placeholder="输入关键字进行过滤" v-model.trim="filterText" prefix-icon="el-icon-search">
      </el-input>
      <el-tree :data="targetLists" :props="defaultProps" @node-click="handleNodeClick" node-key="id"
        :default-expanded-keys="expandedKeys" :current-node-key='currentNodeKey' @node-expand="handleNodeExpand"
        @node-collapse="handleNodeCollapse" :filter-node-method="filterNode" class='JZDepartmentTree'
        ref="JZDepartmentTree">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <el-tooltip placement="top" v-if="data.relationFlag" effect="light">
            <div slot="content">
              <ul>
                <li v-for="(item,index) in  data.oldSerialNoList" :key="`${item}#${index}`">{{item}}</li>
              </ul>
            </div>
            <span>{{ data.serialNo}}{{ node.label }}</span>
          </el-tooltip>
          <span :title="data.name" v-else>{{ data.serialNo }}{{ node.label }}</span>
          <span>
            <i class="el-icon-circle-plus-outline" type="text" size="mini" v-auth="'system:department:add'"
              v-if="data.level!==3" @click.self.stop.prevent="() => handleAdd(node, data)">
            </i>
            <i v-if="data.level!==0" class="el-icon-edit-outline" type="text" size="mini" v-auth="'system:department:edit'"
              @click.self.stop.prevent="() => handleEdit(node, data)">
            </i>
            <i class="el-icon-delete" type="text" size="mini" v-if="!(data.level === 0 && data.initFlag === '1')" v-auth="'system:department:delete'"
              @click.self.stop.prevent="() => handleDelete(node, data)">
            </i>
          </span>
        </span>
      </el-tree>
      <!--<el-button type="primary" class="mt-2" @click="addCompany">添加公司</el-button>-->
    </ContentBlock>
    <ContentBlock title='基本信息' v-if="JZDepartment.id" class="JZContent h-100">
      <el-row class="infoContainer">
        <el-col :span='4' class="labelTitle">组织ID</el-col>
        <el-col :span='8' :title="currentDepartmentInfo.id">{{currentDepartmentInfo.id}}</el-col>
        <el-col :span='4' class="labelTitle">{{ JZDepartment.level === 0 ? '企业名称' : '部门名称' }}</el-col>
        <el-col :span='8' :title="currentDepartmentInfo.name">{{currentDepartmentInfo.name}}</el-col>
        <el-col :span='4' class="labelTitle">直属员工</el-col>
        <el-col :span='20'>{{currentDepartmentInfo.directEmployeeCount}}</el-col>
      </el-row>
      <TableWithPagination :tableData="currentDepartmentInfo.deptEmployRepDtoList" ref="employeeTable"
        :leftToolbar="tableButtons" :columnList="columnList" :pagination.sync="pagination"
        @selection-change="handleSelectionChange" row-key='employId'></TableWithPagination>
    </ContentBlock>
    <el-dialog :title="currentTitle" :visible.sync="isShow" :width="isStatus===3?'50%':'440px'"
      :append-to-body='$store.state.common.appendToBody' :destroyOnClose='$store.state.common.destroyOnClose'
      :close-on-click-modal='$store.state.common.closeOnClickModal'>
      <div class="content" style="max-height: 400px;overflow: auto;">
        <ContentBlock v-if='isStatus===3' title="员工基本信息">
          <el-form label-position="right" ref="JZInfo" :model="JZInfo" label-width="110px">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="姓名：" prop="name">
                  <el-input placeholder="请输入姓名" :readonly="isViewing" v-model.trim="JZInfo.name" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="直属部门：" prop="deptNm">
                  <el-input placeholder="请输入直属部门" :readonly="isViewing" v-model.trim="JZInfo.deptNm" clearable>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="邮箱地址：" prop="email">
                  <el-input placeholder="请输入邮箱地址" :readonly="isViewing" v-model.trim="JZInfo.email" clearable>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号：" prop="phone">
                  <el-input placeholder="请输入手机号" :readonly="isViewing" v-model.trim="JZInfo.phone" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="角色权限：" prop="roleNamesStr">
                  <el-input placeholder="请输入角色权限" :readonly="isViewing" v-model.trim="JZInfo.roleNamesStr" clearable>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="员工状态：" prop="status">
                  <el-input placeholder="请输入员工状态" :readonly="isViewing" v-model.trim="JZInfo.status" clearable>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </ContentBlock>
        <ContentBlock v-if='isStatus===3' title="员工账号">
          <el-form label-position="right" ref="JZForm" :model="JZInfo" label-width="110px">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="账号名称：" prop="phone">
                  <el-input placeholder="请输入账号名称" :readonly="isViewing" v-model.trim="JZInfo.phone" clearable>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="账号状态：" prop="accountStatus">
                  <el-input placeholder="请输入账号状态" :readonly="isViewing" v-model.trim="JZInfo.accountStatus" clearable>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </ContentBlock>
        <ContentBlock v-if='[4,5].includes(isStatus)'>
          <el-form label-position="right" ref="JZForm" :rules="JZAdjustFormRules" :model="JZAdjustForm"
            label-width="110px">
            <el-row>
              <el-col :span="24" v-if='isStatus===5'>
                <el-form-item label="调出部门：" prop="oldDepartmentName">
                  已选择<span style="color:rgba(252, 52, 52, 0.6);"> {{selectedLists.length}} </span>名员工进行调整
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="调出部门：" prop="oldDepartmentName">
                  <el-input placeholder="请输入账号名称" disabled v-model.trim="JZAdjustForm.oldDepartmentName" clearable>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="调入部门：" prop="deptId">
                  <DepartMentSelector key="JZAdjustForm" v-model.trim="JZAdjustForm.deptId" :isAllowLeaf='true'
                    :name.sync="JZAdjustForm.departmentName" @change="handleDepartmentNameChanged"
                    placeholder='请选择调入部门'></DepartMentSelector>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </ContentBlock>
        <el-form label-position="right" ref="JZForm" :rules="JZFormRules" :model="JZForm" label-width="110px"
          v-if='[1,2].includes(isStatus)'>
          <el-form-item label="部门名称：" prop="deptNm">
            <el-input placeholder="请输入部门名称" v-model.trim="JZForm.deptNm" maxlength='10' show-word-limit clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="上级部门：" prop="parentId" v-if="isStatus===1">
            <el-input placeholder="请输入上级部门" v-model.trim="JZForm.parentName" disabled clearable></el-input>
          </el-form-item>
          <el-form-item label="上级部门：" prop="parentId" v-else-if='isStatus===2'>
            <DepartMentSelector key="parentName" v-model.trim="JZForm.parentId" :name.sync="JZForm.parentName"
              @change="handleParentChanged"></DepartMentSelector>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer " style="text-align:right;">
        <el-button @click="resetDept" type='default' plain size="mini">取 消</el-button>
        <el-button @click="handleSaveOrUpdateDepartment" v-if='isStatus!==3' type='primary' size="mini">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="curCompanyType === 'edit' ? '修改公司' : '添加公司'" :visible.sync="companyDialog" width="400px"
      :close-on-click-modal="false" :close-on-press-escape="false" :before-close="resetCompany">
      <el-form label-position="right" ref="companyFormRef" :model="companyForm" label-width="120px"
        :rules="companyRules">
        <el-form-item label="公司名称：" prop="deptNm">
          <el-input placeholder="请输入公司名称" v-model.trim="companyForm.deptNm" clearable required></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer " style="text-align:right;">
        <el-button @click="resetCompany" type='default' plain size="mini">取 消</el-button>
        <el-button @click="saveCompany" type='primary' size="mini">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';
import SystemSetting from '@/api/systemSetting';
import DepartMentSelector from '@/components/departMentSelector';
export default {
  name: 'Department',
  components: {
    DepartMentSelector,
  },
  mixins: [
    InfoMixin,
  ],
  methods: {
    resetDept () {
      this.isShow = false;
      this.JZForm.deptNm = '';
    },
    resetCompany () {
      this.companyForm.id = undefined;
      this.companyForm.deptNm = '';
      this.companyDialog = false;
    },
    saveCompany () {
      this.$refs.companyFormRef.validate((valid) => {
        if (valid) {
          if (this.curCompanyType === 'add') {
            SystemSetting.addNewDepartment(this.companyForm).then(rs => {
              if (rs.heads.code === 200) {
                this.resetCompany();
                this.getTargetLists();
              } else {
                this.$JZShowAlert(rs.heads.message, 'error');
              }
            });
          } else {
            SystemSetting.updateDepartment(this.companyForm).then(rs => {
              if (rs.heads.code === 200) {
                this.resetCompany();
                this.$nextTick(() => {
                  this.$refs.employeeTable.clearCheckboxRow();
                  this.selectedLists = [];
                });
                this.getDetailDepartment();
                this.getTargetLists();
              } else {
                this.$JZShowAlert(rs.heads.message, 'error');
              }
            });
          }
        }
      });
    },
    addCompany () {
      this.curCompanyType = 'add';
      this.companyForm.deptNm = '';
      this.companyDialog = true;
    },
    handleParentChanged (item) {
      this.JZForm.parentName = item.name;
      this.JZForm.parentId = item.id;
    },
    handleDepartmentNameChanged (item) {
      this.JZAdjustForm.parentName = item.name;
      this.JZAdjustForm.deptId = item.id;
    },
    getTargetLists () {
      SystemSetting.getDepartmentLists({}).then(this.getTargetListsSuccessFun);
    },
    getTargetListsSuccessFun (jsonData) {
      let currentLists = [];
      if (jsonData.heads.code === 200) {
        if (jsonData.body.hasOwnProperty('list')) {
          currentLists = jsonData.body.list || [];
        } else {
          currentLists = jsonData.body || [];
        }
      } else {
        this.$JZShowAlert(jsonData.heads.message, 'error');
      }
      this.targetLists = currentLists || [];
      this.JZDepartment = currentLists[0];
      this.$nextTick(() => {
        this.currentNodeKey = currentLists[0].id;
        this.$refs.JZDepartmentTree.setCurrentKey(this.currentNodeKey);
      });
      this.currentNodeKey = currentLists[0].id;
      this.expandedKeys.push(currentLists[0].id);
      this.getDetailDepartment();
    },
    handleNodeClick (info) {
      this.$nextTick(() => {
        this.$refs.employeeTable.clearCheckboxRow();
        this.selectedLists = [];
      });
      this.JZDepartment = info;
      this.currentNodeKey = info.id;
      this.getDetailDepartment();
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 树节点关闭
    handleNodeCollapse (data) {
      this.expandedKeys.some((item, i) => {
        if (item === data.id) {
          // 删除关闭节点
          this.expandedKeys.length = i;
        }
      });
    },
    handleNodeExpand (data) {
      // 保存当前展开的节点
      let flag = false;
      this.expandedKeys.some(item => {
        if (item === data.id) { // 判断当前节点是否存在， 存在不做处理
          flag = true;
          return true;
        }
      });
      if (!flag) { // 不存在则存到数组里
        this.expandedKeys.push(data.id);
      }
    },
    handleAdd (info, data) {
      this.isStatus = 1;
      this.isShow = true;
      this.currentTitle = '新增下级部门';
      this.JZForm = {
        deptNm: '',
        id: '',
        parentId: data.id,
        parentName: data.name,
        remark: '',
      };
    },
    handleEdit (info, data) {
      if (data.level === 0) {
        this.curCompanyType = 'edit';
        this.companyForm.id = data.id;
        this.companyForm.deptNm = data.name;
        this.companyDialog = true;
      } else {
        this.isStatus = 2;
        this.isShow = true;
        this.currentTitle = '修改部门';
        let parentName = '';
        if (data.pid) {
          const parentNode = this.$refs.JZDepartmentTree.getNode(data.pid);
          parentName = parentNode.label || parentName;
          console.log(parentNode);
        }
        this.JZForm = {
          deptNm: data.name,
          id: data.id,
          parentId: data.pid,
          parentName: parentName,
          remark: data.remark,
        };
      }
    },
    handleDelete (info, data) {
      // if (data.children && data.children.length) {
      //   this.$JZShowAlert('该部门下有直属员工，请先将员工删除后再删除该部门', 'error');
      //   return;
      // }
      this.$confirmAlert('你还要继续吗？', '删除该部门后不能再选中该部门').then(() => {
        SystemSetting.deleteDepartment({ id: data.id }).then(jsonData => {
          if (jsonData.heads.code === 200) {
            if (this.JZDepartment.id === data.id) {
              this.JZDepartment = {};
            }
            this.getTargetLists();
          } else {
            this.$JZShowAlert(jsonData.heads.message, 'error');
          }
        });
      });
    },
    getDetailDepartment () {
      SystemSetting.getDetailDepartment({ id: this.JZDepartment.id }).then(jsonData => {
        if (jsonData.heads.code === 200) {
          this.currentDepartmentInfo = jsonData.body;
        } else {
          this.$JZShowAlert(jsonData.heads.message, 'error');
        }
      });
    },
    handleSelectionChange (selectedLists) {
      this.selectedLists = selectedLists;
    },
    handleView (info) {
      this.isStatus = 3;
      this.isShow = true;
      this.currentTitle = '员工详情';
      SystemSetting.getDetailEmploy({ id: info.employId, findDepartmentFlag: true, findRoleFlag: true }).then(jsonData => {
        if (jsonData.heads.code === 200) {
          let currentInfo = jsonData.body;
          this.JZInfo = {
            name: currentInfo.name,
            email: currentInfo.email,
            deptNm: currentInfo.departmentName,
            roleNamesStr: currentInfo.roleNamesStr,
            roleNmList: currentInfo.roleNmList,
            phone: currentInfo.phoneNumber,
            status: currentInfo.statusName, // 员工状态 ON_JOB：在职；LEAVE_OFFICE：离职
            id: currentInfo.id,
            workStatus: currentInfo.workStatusName, // 工作【DISABLE：禁用；ACTIVE：激活】
            accountStatus: currentInfo.accountStatusName,
          };
        } else {
          this.$JZShowAlert(jsonData.heads.message, 'error');
        }
      });
    },
    batchAdjust () {
      this.isStatus = 5;
      this.isShow = true;
      this.currentTitle = '部门员工调整';
      let currentInfo = this.selectedLists[0];
      this.JZAdjustForm = {
        deptId: '',
        departmentName: '',
        oldDepartmentName: currentInfo.deptNm,
        employId: '',
      };
    },
    handleAdjust (info) {
      this.isStatus = 4;
      this.isShow = true;
      this.JZAdjustForm = {
        deptId: '',
        departmentName: '',
        oldDepartmentName: info.deptNm,
        employId: info.employId,
      };
      this.currentTitle = '部门员工调整';
    },
    handleSaveOrUpdateDepartment () {
      this.$refs.JZForm.validate(valid => {
        if (valid) {
          if ([1, 2].includes(this.isStatus)) {
            if (this.JZForm.id) {
              SystemSetting.updateDepartment(this.JZForm).then(jsonData => {
                if (jsonData.heads.code === 200) {
                  this.resetDept();
                  this.$nextTick(() => {
                    this.$refs.employeeTable.clearCheckboxRow();
                    this.selectedLists = [];
                  });
                  this.getDetailDepartment();
                  this.getTargetLists();
                } else {
                  this.$JZShowAlert(jsonData.heads.message, 'error');
                }
              });
            } else {
              SystemSetting.addNewDepartment(this.JZForm).then(jsonData => {
                if (jsonData.heads.code === 200) {
                  this.resetDept();
                  this.getTargetLists();
                } else {
                  this.$JZShowAlert(jsonData.heads.message, 'error');
                }
              });
            }
          } else if ([4, 5].includes(this.isStatus)) {
            let currentParams = {};
            if (this.isStatus === 4) {
              currentParams = {
                deptId: this.JZAdjustForm.deptId,
                employId: [this.JZAdjustForm.employId],
              };
            } else {
              currentParams = {
                deptId: this.JZAdjustForm.deptId,
                employId: this.selectedLists.map(item => item.employId),
              };
            }
            SystemSetting.batchAdjustDepartment(currentParams).then(jsonData => {
              if (jsonData.heads.code === 200) {
                this.resetDept();
                this.selectedLists = [];
                this.$refs.employeeTable.clearCheckboxRow();
                this.getDetailDepartment();
              } else {
                this.$JZShowAlert(jsonData.heads.message, 'error');
              }
            });
          }
        }
      });
    },
  },
  watch: {
    filterText (val) {
      this.$refs.JZDepartmentTree.filter(val);
    },
  },
  computed: {
    isViewing () {
      return this.isStatus === 3;
    },
    tableButtons () {
      return [
        {
          name: '批量调整', // 按钮内容(必填)
          status: 'default', // 按钮样式
          size: 'small', // 大小尺寸
          disabled: !this.selectedLists.length,
          code: 'AddNewReceipt', // code(必填)
          icon: 'vxe-icon--plus', // 按钮内部icon
          permission: 'system:department:batchAdjust', // 权限码，若为空字符串或不设置则表示不进行权限控制
          click: this.batchAdjust, // 点击回调事件
        },
      ];
    },
    columnList () {
      return [
        {
          minWidth: '60px',
          type: 'checkbox',
          label: '全部',
        },
        {
          tooltip: true,
          label: this.JZDepartment?.level === 0 ? '企业名称' : '直属部门名称',
          minWidth: '160px',
          prop: 'deptNm',
        },
        {
          tooltip: true,
          label: '员工姓名',
          minWidth: '150px',
          prop: 'employNm',
        },
        {
          tooltip: true,
          label: '员工电话',
          minWidth: '150px',
          prop: 'phone',
        },
        {
          tooltip: true,
          label: '操作',
          minWidth: '150px',
          prop: 'deptId',
          fixed: true,
          render: (h, { row }) => {
            let actions = [];
            actions.push(h('span', {
              domProps: {
                className: 'table-action',
              },
              directives: [
                {
                  name: 'auth',
                  value: 'system:employee:view',
                },
              ],
              on: {
                click: this.handleView.bind(this, row),
              },
            }, '查看'));
            actions.push(h('span', {
              domProps: {
                className: 'table-action',
              },
              directives: [
                {
                  name: 'auth',
                  value: 'system:department:adjust',
                },
              ],
              on: {
                click: this.handleAdjust.bind(this, row),
              },
            }, '调整'));
            return h('div', actions);
          },
        },
      ];
    },
  },
  data () {
    return {
      curCompanyType: 'add',
      companyDialog: false,
      companyForm: {
        id: undefined,
        deptNm: '',
        parentId: '0',
      },
      companyRules: {
        deptNm: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
      },
      isStatus: 0, // 1: 新增 2：编辑 3：员工详情 4: 调整 5： 批量调整
      currentTitle: '新增下级部门',
      isShow: false,
      JZForm: {
        deptNm: '',
        id: '',
        parentId: '',
        parentName: '',
        remark: '',
      },
      JZInfo: {
        name: '',
        email: '',
        deptNm: '',
        roleNmList: [],
        roleNamesStr: '',
        phone: '',
        status: '', // 员工状态 ON_JOB：在职；LEAVE_OFFICE：离职
        id: '',
        workStatus: '', // 工作【DISABLE：禁用；ACTIVE：激活】
        accountStatus: '',
      },
      JZAccountInfo: {
        accountStatus: '', // 【DISABLE：禁用；ACTIVE：激活】
      },
      JZAdjustForm: {
        deptId: '',
        employId: '',
        departmentName: '',
        oldDepartmentName: '',
      },
      JZAdjustFormRules: {
        deptId: [
          {
            required: true,
            message: '请选择调入部门',
            trigger: ['blur', 'change'],
          },
        ],
      },
      JZFormRules: {
        deptNm:
          [
            {
              required: true, message: '部门名称不可为空', trigger: ['blur', 'change'],
            },
          ],
        parentId: [
          {
            required: true, message: '上级部门不可为空', trigger: ['blur', 'change'],
          },
        ],
      },
      targetLists: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      filterText: '',
      expandedKeys: [],
      currentNodeKey: '',
      JZDepartment: {},
      currentDepartmentInfo: {},
      selectedLists: [],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.JZContainer {
  display: flex;
  background: transparent;
  height: 100%;
  box-sizing: border-box;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  line-height: 32px;
  border-radius: 4px;

  i {
    font-size: 14px;
    color: #d3d3d3;

    &:hover {
      color: #5195EB;
      font-size: 16px;
    }

    &.active {
      color: #2577e3;
      font-size: 16px;
    }
  }
}

.JZContainer {

  .JZContent {
    flex: 1;
    margin-top: 0;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(36, 40, 50, 0.14);
    border-radius: 4px;
    overflow: auto;
  }
}

.JZTreeContainer {
  box-sizing: border-box;
  width: 320px;
  display: flex;
  box-shadow: 0px 4px 6px 0px rgba(36, 40, 50, 0.08);
  border-radius: 4px;
  flex-direction: column;
  margin-right: 8px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 0px rgba(36, 40, 50, 0.08);
  border-radius: 4px;

  &>.el-tree {
    flex: 1;
    &.JZDepartmentTree {
      margin-top: 8px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(36, 40, 50, 0.80);
      letter-spacing: 0;
      font-weight: 400;
      border-radius: 4px;
      // border: 1px solid rgba(36, 40, 50, 0.12);
      height: 400px;
      padding: 12px;
      overflow-y: auto;
    }
  }
}

/deep/ .el-tree-node .el-tree-node__content {
  height: 32px;
  line-height: 32px;

  i {
    margin-left: 5px;
    font-size: 14px;
  }
}

/deep/ .el-tree-node.is-current>.el-tree-node__content {
  background: rgba(15, 137, 255, 0.08);
  border-radius: 4px;
  color: #5195EB;
}

/deep/ .el-tree-node__expand-icon::before {
  content: "\e6e0";
}

/deep/ .el-tree-node.is-current>.el-tree-node__content>.el-tree-node__expand-icon::before {
  content: "\e6e0";
  color: #5195EB;
}

/deep/ .el-tree-node.is-current>.el-tree-node__content>.el-tree-node__expand-icon.is-leaf::before {
  opacity: 0;
}

.infoContainer {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(36, 40, 50, 0.7);
  border-top: 1px solid #EEEEEE;
  border-left: 1px solid #EEEEEE;
  line-height: 32px;
  margin-bottom: 10px;

  .el-col {
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;

    &.labelTitle {
      background: #F8F8F8;
      text-align: right;
      padding-right: 10px;
    }
  }
}
</style>
